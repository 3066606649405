import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/privacy/company-processors.md"

export default function CompanyProcessorsPage() {
  return (
    <LegalLayout
      description="Ready Five uses some other third-party processors for company purposes outside of delivering our services."
      source={source}
      title="Company Processors"
    />
  )
}
